/* Don't forget to use normalize.css or reset.css */
body {
    margin: 0;
}

/* Customize this preloader */
:root {
    --preload-color-background: #212121;
    --preload-color-foreground: #ffffff;
    --preload-color-accent: #03a9f4;
    --preload-gap: 36px;
    --preload-icon-size: 128px;
    --preload-font: 2em Roboto, arial, sans-serif;
    --preload-title: 'METATICKET';
    --preload-spinner-size: 32px;
    --preload-spinner-width: 3px;
}

#app-preloader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: var(--preload-gap);
    justify-content: center;
    align-items: center;
    background: var(--preload-color-background);
    color: var(--preload-color-foreground);
    font: var(--preload-font);
    transition: .3s ease-in-out;
}

#app-preloader .preloader-logo {
    width: var(--preload-icon-size);
    height: var(--preload-icon-size);
    color: var(--preload-color-accent);
    filter: opacity(0);
    animation: preloader-logo-anim .3s .5s ease-out forwards;
}

#app-preloader .preloader-title:before {
    content: var(--preload-title);
    filter: opacity(0);
    animation: preloader-title-anim .3s .6s ease-out forwards;
}

#app-preloader .preloader-spinner {
    position: fixed;
    bottom: var(--preload-gap);
    left: calc(50vw - calc(var(--preload-spinner-size) / 2px));
    width: var(--preload-spinner-size);
    height: var(--preload-spinner-size);
    animation: preloader-spinner .5s linear infinite;
}

#app-preloader .preloader-spinner .preloader-spinner-icon {
    width: var(--preload-spinner-size);
    height: var(--preload-spinner-size);
    stroke: var(--preload-color-foreground);
    stroke-width: var(--preload-spinner-width);
    stroke-dasharray: 314%, 314%;
    animation: preloader-spinner-icon-anim 1s linear infinite alternate;
}

@keyframes preloader-logo-anim {
    0% {
        filter: opacity(0);
        transform: translateY(-30px);
    }

    100% {
        filter: opacity(1);
        transform: translateY(0);
    }
}

@keyframes preloader-title-anim {
    0% {
        filter: opacity(0);
    }

    100% {
        filter: opacity(1);
    }
}

@keyframes preloader-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preloader-spinner-icon-anim {
    0% {
        stroke-dasharray: 0%, 314%;
    }

    100% {
        stroke-dasharray: 314%, 314%;
    }
}