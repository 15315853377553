li.chat_left {
    list-style-type: none;
    display: inline;
    word-break: break-all;
    width: 100%;
}

li.chat_right {
    list-style-type: none;
    display: flex;
    width: 100%;
    word-break: break-all;
    justify-content: flex-end;
}

li.chat_right>div {
    padding: 8px;
    margin: 5px;
    border-radius: 9px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

li.chat_left>div {
    padding: 8px;
    margin: 5px;
    border-radius: 9px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

li.chat_right>div>p {
    padding: 8px;
    border-radius: 9px;
    width: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-left: 20%;
}

li.chat_left>div>p {
    padding: 8px;
    border-radius: 9px;
    width: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 20%;
}


li.chat_right>div>p>div>img {
    max-width: 15rem;
}


li.chat_left>div>p>div>img {
    max-width: 15rem;
}

li.chat_right>div>p>small {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
}

li.chat_left>div>p>small {
    font-size: 10px;
}